const joinBy = (delimiter = '.', ...args) =>
  args.filter(Boolean).join(delimiter);


const processUpdated = ({ data, parentKey, index, parentLabel = '' }) => {
    if (typeof data !== 'object' || data === null) {
      return [
        {
          label: `${parentLabel}.${index}`,
          value: `${parentKey}.${index}`,
          sampleValue: data,
        },
      ];
    }
    
    const entries = Object.entries(data);
    return entries.flatMap(([name, sampleValue]) => {
      const label = joinBy('.', parentLabel, index?.toString(), name);
      const value = joinBy('.', parentKey, index?.toString(), name);
  
      // Create an entry for the current array or object before processing its contents
      const currentEntry = {
        label,
        value,
        sampleValue: typeof sampleValue === 'object' ? JSON.stringify(sampleValue) : sampleValue,
      };
      let childEntries = [];
      if (Array.isArray(sampleValue)) {
        childEntries = sampleValue.flatMap((item, idx) =>
          processUpdated({
            data: item,
            parentKey: value,
            index: idx,
            parentLabel: label,
          })
        );
      } else if (typeof sampleValue === 'object' && sampleValue !== null) {
        childEntries = processUpdated({
          data: sampleValue,
          parentKey: value,
          parentLabel: label,
        });
      } else {
        // For non-object and non-array values, directly return the entry
        return [currentEntry];
      }
      // Return the current container entry along with all child entries
      return [currentEntry, ...childEntries];
    });
};
    
const process = ({ data, parentKey, index, parentLabel = '' }) => {
  if (typeof data !== 'object') {
    return [
      {
        label: `${parentLabel}.${index}`,
        value: `${parentKey}.${index}`,
        sampleValue: data,
      },
    ];
  }
  const entries = Object.entries(data);
  return entries.flatMap(([name, sampleValue]) => {
    const label = joinBy('.', parentLabel, index?.toString(), name);
    const value = joinBy('.', parentKey, index?.toString(), name);
    if (Array.isArray(sampleValue)) {
      return sampleValue.flatMap((item, index) =>
        process({
          data: item,
          parentKey: value,
          index,
          parentLabel: label,
        })
      );
    }
    if (typeof sampleValue === 'object' && sampleValue !== null) {
      return process({
        data: sampleValue,
        parentKey: value,
        parentLabel: label,
      });
    }
    return [
      {
        label,
        value,
        sampleValue,
      },
    ];
  });
};

export const processStepWithExecutions = (steps) => {
  if (!steps) return [];
  return steps
    .filter((step) => {
      const hasExecutionSteps = !!step.executionSteps?.length;
      return hasExecutionSteps;
    })
    .map((step, index) => ({
      id: step.id,
      // TODO: replace with step.name once introduced
      name: `${index + 1}. ${
        (step.appKey || '').charAt(0)?.toUpperCase() + step.appKey?.slice(1)
      }`,
      output: processUpdated({
        data: step.executionSteps?.[0]?.dataOut || {},
        parentKey: `step.${step.id}`,
      }),
    }));
};
